import React from 'react';
import Img from 'gatsby-image';
import Parser from 'html-react-parser';
import Mailto from 'react-protected-mailto';

export const PostContentParser = (content, wpMedia) => {
	return Parser(content, {
		replace: function(domNode) {
			if (domNode.name === 'img') {
				const fluidImg = wpMedia
					? wpMedia.edges.filter(media => {
							return media.node.source_url === domNode.attribs.src.replace(/(-\d+x\d+)(\.jpg|png|webp|jpeg)$/, `$2`);
					  })
					: [];
				if (fluidImg.length > 0) {
					const { localFile, alt_text } = fluidImg[0].node;
					const srcMedia = localFile.childImageSharp ? localFile.childImageSharp.fluid : localFile.publicURL;
					return localFile.childImageSharp ? <Img fluid={srcMedia} /> : <img src={srcMedia} alt={alt_text} />;
				}
			}
			if (domNode.name === 'a' && domNode.attribs.href.includes('mailto:')) {
				return <Mailto email={domNode.attribs.href.replace('mailto:', '')} />;
			}
		},
	});
};

import React from 'react';
import { graphql, Link } from 'gatsby';
import format from 'date-fns/format';

import Layout from '../components/layout';
// import Image from '../components/image';
import SEO from '../components/seo';
import { Card } from '../components/card';

import { Grid, BlogGrid, Sidebar, PostUl, PostLi } from '../components/styles/layout';

const BlogPage = ({ data, location }) => (
	<Layout location={location}>
		<SEO title='Rants and things i love about being an illustrator' />
		<Grid columns={['70%', '24%']} columnGap={'6%'}>
			<BlogGrid>
				{data.allWordpressPost.edges.map(({ node }) => (
					<Card key={node.id} node={node} wpMedia={data.allWordpressWpMedia} />
				))}
			</BlogGrid>
			<Sidebar>
				<h3>Latest posts</h3>
				<PostUl>
					{data.allWordpressPost.edges.map(({ node }) => (
						<PostLi key={node.id}>
							<Link to={node.path}>
								<h4>{node.title}</h4>
								<time itemProp='datePublished' dateTime={node.date}>
									{format(new Date(node.date), 'MMM dd, yyyy')}
								</time>
							</Link>
						</PostLi>
					))}
				</PostUl>
			</Sidebar>
		</Grid>
	</Layout>
);

export default BlogPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
	query {
		allWordpressPost {
			edges {
				node {
					date
					id
					content
					path
					title
					sticky
					excerpt
					author {
						name
					}
				}
			}
		}
		allWordpressWpMedia {
			edges {
				node {
					source_url
					alt_text
					localFile {
						publicURL
						childImageSharp {
							fluid(maxWidth: 600) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
			}
		}
	}
`;

import styled, { css } from 'styled-components';
import { hideVisually } from 'polished';

const Figure = styled.figure`
	/* ${props =>
		props.span &&
		css`
			grid-column-end: span ${Math.ceil(props.span / 10)};
			grid-row-end: span ${props.span};
		`} */
`;
const EntryMeta = styled.p`
	margin-top: -1rem;
	color: #999;
`;

const Author = styled.span`
	${hideVisually()};
`;

export { Figure, Author, EntryMeta };

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import format from 'date-fns/format';

import { PostContentParser } from '../../utils/parser';

import { Figure, EntryMeta, Author } from './styles/card';

const Card = ({ node, wpMedia }) => {
	const { date, title, content, author, path } = node;
	return (
		<article itemScope='' itemType='https://schema.org/CreativeWork'>
			<header>
				<Link to={`/blog/${path}`}>
					<h2 itemProp='headline'>{title}</h2>
				</Link>
				<EntryMeta>
					<time itemProp='datePublished' dateTime={date}>
						{format(new Date(date), 'MMM dd, yyyy')}
					</time>
					<Author itemProp='author' itemScope='' itemType='https://schema.org/Person' aria-hidden='true'>
						{author.name}
					</Author>
				</EntryMeta>
			</header>
			{PostContentParser(content, wpMedia)}
		</article>
	);
};

Card.propTypes = {
	node: PropTypes.object,
};

Card.defaultProps = {
	node: {},
};

const ImageCard = ({ node, index = 0 }) => {
	const { title, featured_media } = node;
	const {
		presentationHeight,
		// presentationWidth,
	} = featured_media.localFile.childImageSharp.sizes;
	const rowHeight = 20;
	const span = Math.ceil(presentationHeight / rowHeight);
	return featured_media ? (
		<Figure span={span} rowHeight={rowHeight} data-index={index}>
			<Img fluid={featured_media.localFile.childImageSharp.fluid} alt={featured_media.alt_text} />
			<figcaption>{title}</figcaption>
		</Figure>
	) : null;
};
ImageCard.propTypes = {
	node: PropTypes.object,
};

ImageCard.defaultProps = {
	node: {},
};

export { Card, ImageCard };
